@import "../../../../assets/mixins/mixins.scss";

.qr_code_wrapper {
    @include size(162px, 162px);
    @include flexCenter();
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    .qr_code {
        @include size(130px, 130px);
        @include flexCenter();
    }
}
.qr_info {
    @include flColStart();
    gap: 10px;
    width: 100%;
    .info_item {
        @include font(12px, "Geo-300", #808080);
        list-style-position: inside;
    }
}
.black_btn {
    @include flexCenter();
    @include font(14px, "Geo-400", #fff);
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    border-radius: 50px;
    background: #14161f;
    border: none;
    transition: 0.3s;
    padding: 11px 30px;
    cursor: pointer;
    &:hover {
        transform: scale(1.05);
    }
}

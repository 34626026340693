@import "../../../../assets/mixins/mixins.scss";

.payments_wrapper {
    @include flexCenter();
    width: 100%;
    padding: 120px 0;
    .payments_main {
        @include flColStart();
        gap: 40px;
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        .info_wrapper {
            @include flColStart();
            width: 100%;
            @media screen and (max-width: 1150px) {
                flex-direction: column;
                gap: 44px;
            }
            .text {
                @include font(16px, "Geo-300", var(--m-text));
                position: relative;
                align-self: flex-end;
                right: 0;
                bottom: 20px;
                max-width: 570px;
                @media screen and (max-width: 1150px) {
                    position: initial;
                    align-self: flex-end;
                }
                @media screen and (max-width: 850px) {
                    max-width: 183px;
                    font-size: 12px;
                }
            }
        }
        .payments_methods_wrapper {
            @include flexCenterStart();
            gap: 12px;
            max-width: var(--m-width);
            position: relative;
            transition: transform 0.5s ease;
            .method {
                @include flexCenter();
                padding: 24px;
                border-radius: 8px;
                background: #f8f8f8;
                & > img {
                    @include size(32px, 32px);
                    object-fit: contain;
                }
            }
            & > button {
                @include flexCenter();
                height: 80px;
                padding: 24px;
                border-radius: 8px;
                background: #f8f8f8;
                border: none;
                cursor: pointer;
            }
            .previous {
                transform: rotate(180deg);
            }
        }
        .download_wrapper {
            @include flexCenterBetween();
            width: 100%;
            .line {
                width: 81%;
                height: 1px;
                background: #14161f;
                @media screen and (max-width: 1000px) {
                    width: 75%;
                }
                @media screen and (max-width: 750px) {
                    display: none;
                }
            }
            .download {
                @include flexCenter();
                @include font(14px, "Geo-400", #fff);
                padding: 15px 30px;
                border-radius: 50px;
                background: #14161f;
                cursor: pointer;
                transition: 0.3s;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

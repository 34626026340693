.section_title {
    color: #14161f;
    font-family: "Geo-300";
    font-size: 92px;
    font-style: normal;
    text-transform: uppercase;
    position: relative;
    @media screen and (max-width: 1115px) {
        font-size: 68px !important;
    }
    @media screen and (max-width: 860px) {
        font-size: 58px !important;
    }
    @media screen and (max-width: 550px) {
        font-size: 48px !important;
    }
}

@import "../../../../assets/mixins/mixins.scss";

.solve_problem_main_wrapper {
    height: calc(100vh + 1200px);

    .solve_problem_wrapper {
        @include flexStartCenter();
        min-height: 100vh;
        position: sticky;
        top: 0;
        bottom: 0;
        padding: 120px 15px;
        gap: 173px;
        @media screen and (max-width: 1024px) {
            gap: 60px;
        }
        @media screen and (max-width: 850px) {
            padding: 90px 15px;
            flex-direction: column;
            top: -400px;
        }
        .texts_wrapper {
            @include flColStart();
            gap: 20px;
            max-width: 510px;
            .text {
                @include font(16px, "Geo-300", var(--m-text));
                @media screen and (max-width: 550px) {
                    font-size: 14px;
                }
            }
        }
        .collapse_wrapper {
            width: 100%;
            max-width: 394px;
            @media screen and (max-width: 850px) {
                max-width: none;
            }
        }
    }
}

@import "../../../../assets/mixins/mixins.scss";

.footer {
    @include flexCenter();
    padding: 185px 0 27px;
    position: relative;
    background: #14161f;
    @media screen and (max-width: 550px) {
        padding: 90px 0 20px;
    }
    .footer_main {
        @include flColCenterStart();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        gap: 196px;
        @media screen and (max-width: 550px) {
            gap: 90px;
        }
        .text_wrapper {
            @include flColStart();
            gap: 20px;
            width: 100%;

            .text {
                @include font(16px, "Geo-300", #fff);
            }
            .email {
                @include font(95px, "Geo-400", #fff);
                text-transform: uppercase;
                background-image: linear-gradient(to right, #fff 0 35%, var(--m-color) 55%, #fff 75% 100%);
                -webkit-background-clip: text;
                color: transparent;
                transition: 0.3s;
                text-decoration: none;
                @media screen and (max-width: 1200px) {
                    font-size: 7.8vw;
                }
                @media screen and (max-width: 550px) {
                    font-size: 7.5vw;
                }
                &:hover {
                    color: var(--m-color);
                    transition: color 0.3s; /* Adjust the transition duration as needed */
                }
            }
        }
        .footer_date {
            @include flexCenterBetween();
            padding-top: 20px;
            width: 100%;
            border-top: 1px solid #2b2f41;
            @media screen and (max-width: 550px) {
                flex-direction: column;
                gap: 24px;
            }
            .date {
                @include font(12px, "Geo-300", #fff);
            }
            .links {
                @include flexCenter();
                gap: 25px;
                & > a {
                    @include font(12px, "Geo-300", #fff);
                    text-decoration: none;
                }
            }
        }
    }
}

@import "../../assets/mixins/mixins.scss";

.masq_logo_wrapper {
    @include flexCenter();
    gap: 13px;
    .icon_wrapper {
        @include flexCenter();
        @include size(36.82px, 36.82px);
        border-radius: 11.969px;
        transition: 0.3s;
    }
}

@import "../../../../assets/mixins/mixins.scss";

.choose_wrapper {
    @include flColCenter();
    gap: 25px;
    width: 100%;
    max-width: 290px;
    margin-top: 20px;
    .info {
        @include font(14px, "Geo-300", #c7c7c7);
        text-align: center;
    }
    .black_btn {
        @include flexCenter();
        @include font(14px, "Geo-400", #fff);
        text-transform: uppercase;
        width: 40%;
        max-width: 290px;
        padding: 11px 30px;
        border-radius: 50px;
        background: #14161f;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            transform: scale(1.1);
        }
        &:disabled {
            background: #c7c7c7;
        }
    }
}
.select {
    & * {
        border-color: #c7c7c7 !important;
    }
}

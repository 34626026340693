.collapse_star_wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.collapse_star_wrapper .ant-collapse-item {
    border-bottom: 1px solid #eeeeee !important;
}
.collapse_star_wrapper .ant-collapse-item:last-child {
    border: none !important;
}
.collapse_star_wrapper .ant-collapse-item-active > .ant-collapse-header {
    padding: 0 0 20px !important;
}
.collapse_star_wrapper .ant-collapse-header {
    padding: 0 0 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 20px !important;
}
.collapse_star_wrapper .ant-collapse-content-box {
    padding: 0 !important;
    margin-bottom: 12px !important;
    font-family: "Geo-300" !important;
    font-size: 14px !important;
    color: var(--m-text) !important;
}
.collapse_star_wrapper .ant-collapse-header-text {
    font-family: "Geo-300";
    font-size: 20px;
    color: var(--m-text);
    text-transform: uppercase;
}

.collapse_star_wrapper .ant-collapse-expand-icon {
    padding: 0 !important;
}

@import "../../../../assets/mixins/mixins.scss";

.title {
    @include font(24px, "Geo-500", var(--m-text));
    text-transform: uppercase;
    align-self: center;
}
.inputs_wrapper {
    @include flColStart();
    width: 290px;
    gap: 20px;
    & > label {
        &:nth-child(2) {
            margin-top: 4px;
        }
    }
    .stay_signed_wrapper {
        @include flexBetween();
        width: 100%;
        .checkbox {
            @include flexStartCenter();
            @include font(12px, "Geo-300", var(--m-text));
            gap: 8px;
            cursor: pointer;
            & > input {
                accent-color: var(--m-color);
            }
        }
        .forget {
            @include font(12px, "Geo-300", #c7c7c7);
            text-decoration: none;
            background: none;
            border: none;
            cursor: pointer;
        }
    }
}
.sign_in_btn {
    @include flexCenter();
    @include font(12px, "Geo-400", #fff);
    width: 100%;
    text-transform: uppercase;
    border-radius: 50px;
    background: #14161f;
    padding: 13px 20px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.1);
    }
}
.dont_have {
    @include flexCenter();
    gap: 10px;
    width: 100%;
    margin-top: 26px;
    & > span {
        @include font(12px, "Geo-300", var(--m-text));
    }
    & > a {
        @include font(12px, "Geo-300", var(--m-color));
        text-decoration: none;
    }
}

.signInError {
    @include font(12px, "Geo-300", #e00252);
    height: 15px;
}

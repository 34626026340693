@import "../../../..//assets/mixins/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.subscription_wrapper {
    @include flexCenter();
    width: 100%;
    padding: 120px 0;
    position: relative;
    background-color: #14161f;
    @media screen and (max-width: 800px) {
        padding: 90px 0;
    }
    .subscription_main {
        @include flColCenterStart();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        gap: 80px;
        .subscription_plans_wrapper {
            @include flexCenter();
            gap: 12px;
            @media screen and (max-width: 1200px) {
                flex-wrap: wrap;
            }

            .subscription {
                width: 290px;
                height: 685px;
                background-color: #fff;
                border-radius: 15px;
                padding: 26px 28px;

                .subscriptionName {
                    color: #000;
                    font-family: "Geo-400";
                    font-size: 20px;
                    text-transform: capitalize;
                }

                .subscriptionSubName {
                    color: #808080;
                    font-family: "Inter", sans-serif;
                    font-weight: 400;
                    font-size: 11px;
                    margin: 16px 0 26px;
                }

                .priceAndDuration {
                    .price {
                        color: #292929;
                        font-family: "Inter";
                        font-size: 32px;
                        font-weight: 500;
                        line-height: 12px;
                    }
                    .duration {
                        color: #808080;
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 32px;
                        margin-left: 5px;
                    }
                }

                .perAdditionalSeat {
                    @extend .priceAndDuration;
                    margin-top: 12px;
                }

                .getStartedBtn {
                    @include flexCenter();
                    width: 234px;
                    height: 37px;
                    border-radius: 8px;
                    background: linear-gradient(90deg, #560bad 0%, #59a8f0 100%);
                    color: #fff;
                    font-family: "Inter";
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                    margin: 24px 0;
                }

                .optionsTitle {
                    color: #3b3b3b;
                    font-family: "Inter";
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                .optionsItemWrapper {
                    & p {
                        display: flex;
                        height: 29px;
                        gap: 16px;
                        align-items: center;
                        color: #3b3b3b;
                        font-family: "Inter";
                        font-size: 11px;
                        font-weight: 400;
                        .unlimitedSymbol {
                            font-size: 15px;
                            margin-right: 5px;
                        }
                    }
                }
            }

            .proStyles {
                background: linear-gradient(180deg, #7b4ff7 0%, #560bad 100%);
                .getStartedBtn {
                    background: linear-gradient(90deg, #4378ff 0%, #4893d8 100%);
                }
                & * {
                    color: #fff !important;
                }
            }
        }
    }
}

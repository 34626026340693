@import "../../../../assets/mixins/mixins.scss";

.faq_wrapper {
    @include flexCenter();
    width: 100%;
    position: relative;
    padding: 120px 0;
    background: url("../../../../assets/images/grainy.png");

    .faq_main {
        @include flexBetween();
        max-width: var(--m-width);
        width: 100%;
        padding: 15px;
        gap: 30px;
        @media screen and (max-width: 850px) {
            flex-direction: column;
            align-items: center;
            row-gap: 80px;
            column-gap: 0;
        }
        .info_wrapper {
            @include flColStart();
            width: 39.14%;
            gap: 140px;
            @media screen and (max-width: 850px) {
                width: 100%;
                flex-direction: row;
            }
            .text_btn {
                @include flColStart();
                gap: 24px;
                .text {
                    @include font(16px, "Geo-300", var(--m-text));
                    list-style-position: outside;
                }
                .assistant {
                    @include flexCenter();
                    @include font(14px, "Geo-400", #fff);
                    padding: 15px 30px;
                    text-transform: uppercase;
                    border-radius: 50px;
                    background: #14161f;
                    cursor: pointer;
                    transition: 0.3s;
                    border: none;
                    text-decoration: none;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .collapse_wrapper {
            width: 585px;
            @media screen and (max-width: 850px) {
                width: 100%;
            }
        }
    }
}

@import "../../assets/mixins/mixins.scss";

.notify_main_wrapper {
    position: fixed;
    z-index: 1001;
    top: 24px;
    right: 0;
    width: 0;
    font-family: "Geo-300" !important;

    .notify_main_container {
        position: relative;
        right: -20px;
        width: 380px;
        height: 58px;
        border-radius: 16px;
        background: #fff;
        box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        cursor: pointer;

        .fn_btn {
            position: absolute;
            top: 18px;
            right: 52px;
            color: #fff;
            background-color: var(--m-c);
            padding: 0 12px;
            height: 22px;
            display: flex;
            align-items: center;
            border-radius: 12px;
            font-weight: 400;
            font-size: 12px;
        }

        .notification_color_container {
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 100%;

            .notification_color_duration {
                position: absolute;
                width: 16px;
                left: 0;
                bottom: 0;
                height: 100%;
                background-color: rgba(252, 234, 42, 0.265);
                animation-name: notify_duration_anm;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }

        .notify_messages_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2px;
            width: 250px;
            margin-left: 36px;
            height: 100%;
            & > p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: "Geo-400";
            }
        }
        .notify_title {
            @include font(13px, "Geo-400", var(--m-text));
            line-height: 18px;
        }

        .notify_sub_title {
            @include font(11px, "Geo-300", #c3c3c3);
            line-height: 15px;
        }
    }

    .show_notify {
        animation-name: notify_show_anm;
        animation-iteration-count: 1;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
    }

    .hide_notify {
        animation-name: notify_hide_anm;
        animation-iteration-count: 1;
        animation-duration: 200ms;
        animation-fill-mode: forwards;
    }
}

@keyframes notify_show_anm {
    0% {
        right: -20px;
    }
    100% {
        right: 400px;
    }
}

@keyframes notify_hide_anm {
    0% {
        right: 400px;
    }
    100% {
        right: -20px;
    }
}

@keyframes notify_duration_anm {
    0% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}

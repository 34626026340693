@import "../../../../assets/mixins/mixins.scss";

.change_step {
    @include flexBetween();
    width: 380px;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebedef;
    gap: 30px;
    @media screen and (max-width: 800px) {
        width: 90%;
    }
    .send_code_part_text_place {
        @include flColStart();
        gap: 8px;
        max-width: 65%;
    }
    .send_code_title {
        @include font(14px, "Geo-300", var(--m-text));
    }
    .send_code_subtitle {
        @include font(12px, "Geo-300", #c7c7c7);
    }
    .confirm_code_part {
        @include flColCenterStart();
        gap: 10px;
        width: 100%;
        .confirm_code_text_place {
            @include flColStart();
            width: 100%;
            gap: 8px;
        }
        .confirm_code_input_place {
            @include flexBetween();
            align-items: flex-start;
            width: 100%;
            gap: 20px;
            & > button {
                margin-top: 7px;
            }
        }
    }
}
.info_step {
    @include flexCenterBetween();
    width: 380px;
    @media screen and (max-width: 800px) {
        width: 90%;
    }
    .disabled_part_header {
        @include font(14px, "Geo-300", #c7c7c7);
        .chechked_icon {
            @include size(22px, 22px);
        }
    }
    .disabled_part_header.confirmed {
        color: var(--m-color);
    }
}

.text_btn {
    @include font(12px, "Geo-300", var(--m-color));
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 18px;
    &:disabled {
        color: #c7c7c7;
    }
}

.vi__wrapper input::placeholder {
    color: #fff !important;
    background: #fff !important;
}

.vf_character {
    border: 1px solid #e8ebed;
    border-radius: 5px !important;
    font-size: 14px;
    font-family: "Geo-300";
    text-transform: uppercase;
    height: 30px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vf_character.active {
    border-color: rgba(229, 0, 0, 0.6) !important;
    background: rgba(229, 0, 0, 0.05);
    animation: shake 0.7s forwards;
}
.vf_character_selected {
    outline-color: var(--m-c);
    color: var(--m-c);
}

.vf_character_inactive {
    background-color: #fff;
}

@keyframes shake {
    0% {
        transform: rotate(0);
    }
    10% {
        transform: rotate(10deg);
    }
    20% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(-10deg);
    }
    40% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0);
    }
    70% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(0);
    }
    90% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}

.more_benefits_main {
    max-width: 866px;
    min-width: 300px;
    width: 90%;
    min-height: 452px;
    border-radius: 20px;
    background: #fff;
    padding-left: 48px;
    position: relative;

    .more_benefits_images_wrapper {
        .mindsetImg {
            position: absolute;
            height: 480px;
            right: 0;
            top: 0;
            z-index: 2;
        }
        .facebookImg {
            position: absolute;
            height: 90px;
            right: 72px;
            top: 9px;
        }
        .amazonImg {
            position: absolute;
            height: 110px;
            right: 180px;
            top: 62px;
        }
        .ebayImg {
            position: absolute;
            height: 110px;
            right: 160px;
            bottom: 100px;
        }
        .googleImg {
            position: absolute;
            height: 85px;
            right: 80px;
            bottom: 15px;
        }
    }
    .main_content {
        position: relative;
        z-index: 3;
        font-size: 24px;

        .benefits_title_first,
        .benefits_title_second {
            color: #292929;
            font-family: "Geo-300";
            text-transform: uppercase;
            margin-top: 60px;
        }

        .benefits_title_second {
            margin-top: 0;
            margin-bottom: 24px;
            & span:nth-of-type(1) {
                color: var(--m-color);
            }
        }

        .benefits_sub_title_text {
            color: #9d9d9d;
            font-family: "Geo-300";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
            max-width: 415px;
            margin-bottom: 64px;
        }
        .promo_code_wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            align-items: center;
            margin-bottom: 50px;
            font-size: 16px;

            .with_promo_text {
                color: #292929;
                font-family: "Geo-300";
                text-transform: uppercase;
                white-space: nowrap;
            }
            .with_promo_btn {
                color: #fff;
                font-family: "Geo-400";
                font-weight: 400;
                text-transform: uppercase;
                background-color: #000;
                padding: 12px 32px;
                cursor: pointer;
                transition-duration: 100ms;
                &:active {
                    transform: scale(0.9);
                }
            }
        }
        .skip_start_btns_wrapper {
            font-family: "Geo-400";
            font-size: 16px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 24px;

            .start_btn {
                color: #fff;
                padding: 12px 32px;
                background-color: var(--m-color);
                width: fit-content;
                border-radius: 10px;
                cursor: pointer;
                white-space: nowrap;
            }
            .skip_btn {
                padding: 12px 0;
                white-space: nowrap;
                width: fit-content;
                border-radius: 10px;
                cursor: pointer;
            }
        }
        .exclusive_text {
            color: #9d9d9d;
            font-family: "Geo-300";
            font-size: 11px;
            text-transform: uppercase;
        }
    }
}

@media screen and (max-width: 850px) {
    .more_benefits_main {
        padding: 0 20px;
    }
    .more_benefits_images_wrapper {
        opacity: 0.5;
    }
    .main_content {
        font-size: 18px !important;
    }
    .benefits_sub_title_text {
        font-size: 14px !important;
        margin-bottom: 30px !important;
    }
    .promo_code_wrapper {
        font-size: 14px !important;
        margin-bottom: 30px !important;
        .with_promo_btn {
            padding: 8px 20px !important;
        }
    }
    .skip_start_btns_wrapper {
        font-size: 12px !important;
        .start_btn {
            padding: 8px 20px !important;
        }
    }
}

@media screen and (max-width: 450px) {
    .more_benefits_images_wrapper {
        opacity: 0.07;
    }
}

@import url("./assets/fonts/fonts.scss");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    outline: none;
    text-decoration: none;
    &:not(.vi, input)::selection {
        background-color: #00000000;
        color: inherit;
    }
}

:root {
    --m-width: 1200px;
    --m-text: #14161f;
    --white: #fff;
    --m-color: #7b4ff7;
}

@import "../../../../assets/mixins/mixins.scss";

.convert_subs_wrapper {
    @include flColCenterStart();
    gap: 20px;
    .convert_info {
        @include font(16px, "Geo-400", #26af60);
        text-align: center;
    }
    .convert_wrapper {
        @include flColCenterStart();
        gap: 15px;
        width: 100%;
        .subtotal_info {
            @include flexCenterBetween();
            width: 100%;
            & > p {
                @include font(16px, "Geo-400", var(--m-text));
            }
        }
        .convert_info_wrapper {
            @include flColStart();
            gap: 8px;
            padding-left: 10px;
            & > li {
                @include font(12px, "Geo-300", #c7c7c7);
            }
        }
        .black_btn {
            @include flexCenter();
            @include font(14px, "Geo-400", #fff);
            text-transform: uppercase;
            padding: 11px 30px;
            border-radius: 50px;
            background: #14161f;
            border: none;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                transform: scale(1.1);
            }
            &:disabled {
                background: #c7c7c7;
            }
        }
    }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.termsAndPrivacyWrapper {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    padding-bottom: 60px;

    & > div {
        height: 100%;
        width: 50%;
    }

    & h1 {
        margin-top: 60px;
    }
    & h2 {
        font-size: 18px;
        margin: 20px 0 0 0;
    }

    & h3 {
        color: #292929;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    & p {
        margin: 16px 0;
        font-weight: 300;
        font-size: 14px;
        color: #808080;
    }

    & a {
        color: #59a8f0;
        text-decoration: underline;
        margin: 0 5px;
    }

    & ul {
        padding-left: 30px;
        margin-top: 10px;
    }
}

@import "../../../../assets/mixins/mixins.scss";

.fingerprints_wrapper {
    @include flexCenter();
    width: 100%;
    padding: 120px 0;
    @media screen and (max-width: 1080px) {
        padding: 70px 0 90px;
    }
    .fingerprints_main {
        @include flexBetween();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        @media screen and (max-width: 1080px) {
            flex-direction: column;
            align-items: center;
        }
        .fingerprint_icon {
            @include flexCenter();
            @include size(386px, 419px);
            position: relative;
            @media screen and (max-width: 1080px) {
                transform: scale(0.75);
            }
            & > div {
                position: absolute;
            }
            .big_border {
                transform: scale(1.3);
            }
            .small_border {
                transform: scale(1);
            }
            .ebay_icon {
                @include flexCenter();
                border: 1px solid #eee;
                border-radius: 50%;
                background: white;
                width: 83.476px;
                height: 83.476px;
                top: -10px;
                right: 17.96px;
            }
            .fb_icon {
                left: 0;
                top: 42px;
            }
            .google_icon {
                left: 11px;
                bottom: 32.52px;
            }
            .amazon_icon {
                right: 31px;
                bottom: 71px;
            }
        }
        .fingerprint_info_wrapper {
            @include flColStart();
            gap: 20px;
            max-width: 613px;
            @media screen and (max-width: 1080px) {
                align-items: center;
                & > h3 {
                    text-align: center;
                }
            }
            .info {
                @include font(16px, "Geo-300", var(--m-text));
                @media screen and (max-width: 1080px) {
                    text-align: center;
                }
                @media screen and (max-width: 550px) {
                    font-size: 12px;
                }
            }
            .try_it {
                @include flexCenter();
                @include font(14px, "Geo-400", #fff);
                padding: 15px 30px;
                cursor: pointer;
                border-radius: 50px;
                background: #14161f;
                border: none;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

@import "../../../../assets/mixins/mixins.scss";

.each_profile_wrapper {
    @include flexCenter();
    width: 100%;
    padding: 120px 0;
    position: relative;
    .each_profile_main {
        @include flColStart();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        gap: 48px;
        .info_wrapper {
            @include flexCenterStart();
            width: 100%;
            max-width: 847px;
            position: relative;

            & > h3 {
                max-width: 600px;
            }

            @media screen and (max-width: 1150px) {
                width: 100%;
                max-width: 100%;
                flex-direction: column;
                gap: 40px;
            }
            .each_profile_text {
                @include font(16px, "Geo-300", var(--m-text));
                position: absolute;
                list-style-position: outside !important;
                max-width: 451px;
                right: -104px;
                bottom: 42px;
                gap: 15px;
                @media screen and (max-width: 1150px) {
                    position: initial;
                    align-self: flex-end;
                }
                @media screen and (max-width: 850px) {
                    max-width: 350px;
                }
                @media screen and (max-width: 550px) {
                    max-width: 200px;
                    font-size: 12px;
                }
            }
        }
        .profiles_wrapper {
            @include flColEndStart();
            gap: 5px;
            align-self: flex-end;
            .first_line {
                margin-right: 48px;
            }
            .second_line {
                margin-right: 96px;
            }
            @media screen and (max-width: 550px) {
                .first_line {
                    justify-content: flex-end;
                    margin-right: 0;
                    flex-wrap: wrap-reverse;
                }
                .second_line {
                    margin-right: 10px;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
                .third_line {
                    margin-right: 20px;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
            }
            & > div {
                @include flexCenterStart();
                gap: 5px;
                & > p {
                    @include font(14px, "Geo-400", var(--m-text));
                    padding: 15px 30px;
                    border-radius: 50px;
                    border: 1px solid #14161f;
                    background: #fff;
                    transition: 0.3s;
                    text-transform: uppercase;
                    cursor: pointer;
                    &:hover {
                        color: #fff;
                        border-color: var(--m-color);
                        background: var(--m-color);
                    }
                    @media screen and (max-width: 850px) {
                        font-size: 12px;
                        padding: 15px 20px;
                    }
                }
            }
        }
    }
}

@import "../../assets/mixins/mixins.scss";

.sign_in_wrapper {
    @include flexStartCenter();
    width: 100%;
    min-height: 100vh;
    background: #14161f;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        padding: 15px;
    }
    @media screen and (max-width: 500px) {
        align-items: flex-start;
    }
    .signIn_left {
        @include flexStartCenter();
        width: 55%;
        padding-left: 20px;
        @media screen and (max-width: 800px) {
            padding: 0;
        }
        .signIn_left_main {
            width: 100%;
            max-width: 554px;
            @include flColStart();
            margin-top: 20px;
            gap: 177px;
            @media screen and (max-width: 800px) {
                gap: 40px;
            }
            .info_wrapper {
                @include flColStart();
                gap: 17px;
                .info {
                    @include font(14px, "Geo-300", #fff);
                    margin-top: 10px;
                    max-width: 240px;
                }
            }
        }
    }
    .signIn_right {
        @include flexCenter();
        width: 45%;
        min-height: 100vh;
        background: #fff;
        justify-self: flex-end;
        @media screen and (max-width: 1050px) {
            padding: 199px 20px 0 50px;
        }
        @media screen and (max-width: 800px) {
            width: 70%;
            min-height: initial;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            padding: 44px 20px 32px;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        .sign_in_right_main {
            @include flColStart();
            gap: 24px;
            .title {
                @include font(24px, "Geo-500", var(--m-text));
                text-transform: uppercase;
                align-self: center;
            }
            .inputs_wrapper {
                @include flColStart();
                width: 290px;
                gap: 12px;
                & > label {
                    &:nth-child(2) {
                        margin-top: 4px;
                    }
                }
                .stay_signed_wrapper {
                    @include flexBetween();
                    width: 100%;
                    .checkbox {
                        @include flexStartCenter();
                        @include font(12px, "Geo-300", var(--m-text));
                        gap: 8px;
                        cursor: pointer;
                        & > input {
                            accent-color: var(--m-color);
                        }
                    }
                    .forget {
                        @include font(12px, "Geo-300", #c7c7c7);
                        text-decoration: none;
                    }
                }
            }
            .sign_in_btn {
                @include flexCenter();
                @include font(12px, "Geo-400", #fff);
                width: 100%;
                text-transform: uppercase;
                border-radius: 50px;
                background: #14161f;
                padding: 13px 20px;
                border: none;
                cursor: pointer;
                transition: 0.3s;
                &:hover {
                    transform: scale(1.1);
                }
            }
            .dont_have {
                @include flexCenter();
                gap: 10px;
                width: 100%;
                margin-top: 26px;
                & > span {
                    @include font(12px, "Geo-300", var(--m-text));
                }
                & > a {
                    @include font(12px, "Geo-300", var(--m-color));
                    text-decoration: none;
                }
            }
        }
    }
}

@import "../../../../assets/mixins/mixins.scss";
.works_main_wrapper {
    height: calc(100vh + 1000px);

    .works_wrapper {
        @include flexCenter();
        position: sticky;
        top: 0;
        bottom: 0;
        padding: 120px 0;
        width: 100%;
        min-height: 100vh;
        @media screen and (max-width: 850px) {
            padding: 90px 0;
            top: -210px;
        }
        .works_main {
            @include flColStart();
            gap: 53px;
            width: 100%;
            max-width: var(--m-width);
            padding: 0 15px;
            @media screen and (max-width: 1150px) {
                align-items: center;
            }
            .info_wrapper {
                @include flexStartStart();
                width: 100%;
                gap: 52px;
                position: relative;
                @media screen and (max-width: 1150px) {
                    flex-direction: column;
                    align-items: center;
                }
                .image {
                    max-width: 594px;
                    width: 100%;
                    height: 351px;
                    position: relative;
                    & img {
                        border-radius: 16px;
                        border: 1px solid #eee;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition-duration: 400ms;
                    }
                    @media screen and (max-width: 1150px) {
                        top: 0 !important;
                    }
                    @media screen and (max-width: 550px) {
                        height: 195px;
                        top: 0 !important;
                    }
                }
                .collapse_wrapper {
                    width: 100%;
                    max-width: 533px;
                }
            }
        }
    }
}

@import "../../../../assets/mixins/mixins.scss";

.app_photo_wrapper {
    @include flColCenterStart();
    gap: 12.56px;
    position: relative;
    width: 100%;
    background-color: #f1f1f1;
    z-index: -3;
    .first {
        @include flexCenterBetween();
        width: 100%;
        height: 215px;
        @media screen and (max-width: 850px) {
            padding-top: 15px;
        }
        & > div {
            &:nth-child(1) {
                width: 35.68%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/first.png");
                background-position: right bottom;
                background-size: cover;
                position: relative;
                z-index: -3;
                @media screen and (max-width: 550px) {
                    width: 48%;
                }
            }
            &:nth-child(2) {
                @include flColCenterStart();
                width: 30%;
                padding: 0 40px;
                gap: 15px;
                & > h2 {
                    text-align: center;
                    font-size: 16px;
                    text-transform: uppercase;
                    @media screen and (max-width: 850px) {
                        font-size: 14px !important;
                    }
                    @media screen and (max-width: 550px) {
                        font-size: 12px !important;
                        text-align: end;
                    }
                    &:nth-child(1) {
                        @include font(16px, "Geo-500", var(--m-text));
                    }
                    &:nth-child(2) {
                        @include font(16px, "Geo-300", var(--m-text));
                    }
                }
            }
            &:nth-child(3) {
                width: 27.92%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/second.png");
                background-position: left bottom;
                background-size: cover;
                position: relative;
                z-index: -3;
                @media screen and (max-width: 550px) {
                    display: none;
                }
            }
        }
    }
    .second {
        @include flexStartStart();
        gap: 12.56px;
        width: 100%;
        height: 304.016px;
        @media screen and (max-width: 550px) {
            height: 230px;
        }
        & > div {
            &:nth-child(1) {
                @include flColCenter();
                width: 23.75%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/third.png");
                background-position: top right;
                background-size: cover;
                position: relative;
                z-index: -3;
                @media screen and (max-width: 850px) {
                    width: 10%;
                }
            }
            &:nth-child(2) {
                width: 35.68%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/forth.png");
                background-position: top left;
                background-size: cover;
                position: relative;
                z-index: -3;
                @media screen and (max-width: 850px) {
                    width: 75%;
                }
            }
            &:nth-child(3) {
                width: 35.68%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/fifth.png");
                background-position: top left;
                background-size: cover;
                position: relative;
                z-index: -3;
                opacity: 0.3;
                @media screen and (max-width: 850px) {
                    width: 10%;
                }
            }
        }
    }
    .third {
        @include flexCenterEnd();
        width: 100%;
        height: 158.85px;
        gap: 12.56px;
        @media screen and (max-width: 550px) {
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            height: 320px !important;
        }
        & > div {
            &:nth-child(1) {
                @include flexCenter();
                @include font(16px, "Geo-500", var(--m-text));
                text-transform: uppercase;
                width: 37%;
                height: 100%;
                padding: 0 160px;
                @media screen and (max-width: 850px) {
                    padding: 0 20px;
                    font-size: 14px !important;
                    text-align: start !important;
                    justify-content: flex-start !important;
                }
                @media screen and (max-width: 550px) {
                    width: 40%;
                    height: 45%;
                    font-size: 12px !important;
                }
            }
            &:nth-child(2) {
                width: 35.68%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/sixth.png");
                background-position: top left;
                background-size: cover;
                position: relative;
                z-index: -3;
                opacity: 0.3;
                @media screen and (max-width: 550px) {
                    width: 55%;
                    height: 45%;
                    font-size: 12px !important;
                }
            }
            &:nth-child(3) {
                width: 25.68%;
                height: 100%;
                background: url("../../../../assets/images/appPhotos/seven.png");
                background-position: top left;
                background-size: cover;
                position: relative;
                z-index: -3;
                @media screen and (max-width: 550px) {
                    width: 90%;
                    height: 45%;
                }
            }
        }
    }
}

@import "../../../assets/mixins/mixins.scss";

.account_wrapper {
    @include flColCenterStart();
    width: 100%;
    gap: 20px;
    .account_main {
        @include flColCenterStart();
        width: 100%;
        height: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        gap: 20px;
        .sub_promo_wrapper {
            @include flexBetween();
            width: 100%;
            height: 100%;
            @media screen and (max-width: 920px) {
                flex-direction: column;
                align-items: center;
            }
            .sub_wrapper {
                @include flColStart();
                width: 50%;
                gap: 20px;
                padding: 35px 40px;
                border-radius: 20px 0px 0px 20px;
                background: #7445f7;
                height: 320px;
                @media screen and (max-width: 920px) {
                    width: 95%;
                    border-radius: 20px 20px 0px 0px;
                }
                @media screen and (max-width: 550px) {
                    padding: 30px;
                }
                .sub_info_wrapper {
                    @include flexBetween();
                    width: 100%;
                    padding-bottom: 63px;
                    border-bottom: 1px solid transparent;
                    border-image: linear-gradient(to right, #ffffff39, transparent) 1;
                    @media screen and (max-width: 550px) {
                        flex-direction: column;
                    }
                    .sub_info {
                        @include flColStart();
                        gap: 20px;
                        .tarrif_title {
                            @include font(14px, "Geo-300", #c4aeff);
                            text-transform: uppercase;
                        }
                        .tarrit_name {
                            @include font(57px, "Geo-300", #fff);
                            text-transform: uppercase;
                            white-space: nowrap;
                        }
                    }
                    .sub_status {
                        @include flColEndStart();
                        gap: 33px;
                        @media screen and (max-width: 550px) {
                            flex-direction: row;
                        }
                        .status {
                            @include flexCenter();
                            @include font(12px, "Geo-500", #fff);
                            padding: 6px 30px;
                            border-radius: 50px;
                            text-transform: uppercase;
                        }
                        .active_date {
                            @include flColEndStart();
                            gap: 5px;
                            & > span {
                                &:nth-child(1) {
                                    @include font(12px, "Geo-400", #c4aeff);
                                    text-transform: uppercase;
                                }
                                &:nth-child(2) {
                                    @include font(12px, "Geo-400", #fff);
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
                .btns_wrapper {
                    @include flexCenter();
                    gap: 12px;
                    & > a {
                        @include flexCenter();
                        @include font(12px, "Geo-400", #fff);
                        padding: 13px 24px;
                        border-radius: 50px;
                        background: #14161f;
                        cursor: pointer;
                        border: none;
                        transition: 0.3s;
                        text-transform: uppercase;
                        text-decoration: none;
                        &:hover {
                            transform: scale(1.1);
                        }
                        &:nth-child(2) {
                            background: #fff;
                            color: #7445f7;
                        }
                    }
                }
            }
            .promo_wrapper {
                @include flColCenter();
                width: 50%;
                gap: 12px;
                padding: 65.8px 40px;
                border-radius: 0px 20px 20px 0px;
                background: #14161f;
                height: 320px;
                @media screen and (max-width: 920px) {
                    width: 95%;
                    border-radius: 0px 0px 20px 20px;
                }
                @media screen and (max-width: 550px) {
                    padding: 30px;
                }
                .promo_title {
                    @include font(24px, "Geo-400", #fff);
                    text-transform: uppercase;
                    @media screen and (max-width: 550px) {
                        font-size: 20px;
                    }
                }
                .promo_subtitle {
                    @include font(12px, "Geo-300", #c7c7c7);
                    text-align: center;
                    max-width: 317px;
                }
                .promo_inp {
                    @include font(12px, "Geo-300", #505362);
                    width: 100%;
                    max-width: 320px;
                    padding: 12px 20px;
                    border-radius: 12px;
                    background: rgba(245, 245, 245, 0.07);
                    outline: none;
                    border: none;
                    &::placeholder {
                        @include font(12px, "Geo-300", #505362);
                    }
                    &:focus {
                        background: #fff;
                    }
                }
                .promo_btns {
                    @include flexCenter();
                    gap: 42px;
                    @media screen and (max-width: 550px) {
                        flex-direction: column;
                        gap: 20px;
                    }
                    .activate_promo {
                        @include flexCenter();
                        @include font(12px, "Geo-500", #fff);
                        padding: 13px 24px;
                        border-radius: 50px;
                        background: var(--1, linear-gradient(180deg, #7b4ff7 0%, #6936f5 100%));
                        text-transform: uppercase;
                        transition: 0.3s;
                        cursor: pointer;
                        border: none;
                        &:hover {
                            transform: scale(1.1);
                        }
                        &:disabled {
                            background: #c7c7c7;
                        }
                    }
                    .cant_activate {
                        @include font(10px, "Geo-500", #fff);
                        cursor: pointer;
                        background: none;
                        border: none;
                        transition: 0.3s;
                        text-decoration: none;
                        &:hover {
                            color: var(--m-color);
                        }
                    }
                }
            }
        }
    }
    .blocks_wrapper {
        @include flexCenterBetween();
        width: 100%;
        flex-wrap: wrap;
        gap: 20px;
        @media screen and (max-width: 850px) {
            justify-content: center;
        }
        .block {
            @include flColStart();
            width: 30%;
            height: 258px;
            padding: 40px 78px 40px 40px;
            border-radius: 20px;
            box-shadow: 1px 1px #7b4ff7;
            background-color: #f6f8fa;
            gap: 66px;
            transition: 0.3s;
            cursor: pointer;
            @media screen and (max-width: 850px) {
                width: 80%;
            }
            &:nth-child(2) {
                box-shadow: -1px -1px #7b4ff7;
            }
            &:nth-child(3) {
                box-shadow: -1px 1px #7b4ff7;
            }
            &:hover {
                transform: scale(1.05);
            }
            .icon {
                @include size(52px, 52px);
                @include flexCenter();
                border-radius: 50%;
                background: linear-gradient(140deg, #ffffff, #6742fbcc, #b7a7f7, #ffffff);
            }
            .blocks_info {
                @include flColStart();
                gap: 11px;
                .block_title {
                    @include font(14px, "Geo-500", var(--m-text));
                    text-transform: uppercase;
                    max-width: 258px;
                }
                .block_subtitle {
                    @include font(12px, "Geo-300", #93949f);
                }
            }
        }
    }
}

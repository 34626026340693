@import "../../../../assets/mixins/mixins.scss";

.advantages_wrapper {
    @include flexCenter();
    width: 100%;
    position: relative;
    background-color: #14161f;
    padding: 107px 0 114px;
    z-index: 2;
    .advantages_main {
        @include flColCenter();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        gap: 50px;
        .advantages_title {
            @include font(95px, "Geo-400", #fff);
            text-transform: uppercase;
            text-align: center;
            & > span {
                color: #14161f;
                text-shadow: -0.7px 0.7px 0 #fff, 0.7px 0.7px 0 #fff, 0.7px -0.7px 0 #fff, -0.7px -0.7px 0 #fff;
            }
        }
        .cards_wrapper {
            @include flexStartCenter();
            gap: 30px;
            flex-wrap: wrap;
            .card {
                @include size(370px, 186px);
                @include flColStart();
                gap: 20px;
                background: var(--1, linear-gradient(180deg, #7b4ff7 0%, #6936f5 100%));
                padding: 28px;
                transition: 0.3s;
                cursor: pointer;
                &:hover {
                    transform: rotate(-5deg) !important;
                }
                &:nth-child(1),
                &:nth-child(6) {
                    box-shadow: 0px 34px 44px 0px rgba(105, 54, 245, 0.2);
                }
                &:nth-child(2),
                &:nth-child(4) {
                    background: transparent;
                    border: 1px solid #fff;
                }
                &:nth-child(3),
                &:nth-child(5) {
                    background: #fff;
                    & > p {
                        color: var(--m-text);
                    }
                }
                .cards_title {
                    @include font(16px, "Geo-500", #fff);
                    text-transform: uppercase;
                }
                .cards_text {
                    @include font(14px, "Geo-300", #fff);
                    @media screen and (max-width: 550px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.expend_number {
    font-family: "Geo-500";
    font-size: 15px !important;
}

.collapse_number_wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.collapse_number_wrapper .ant-collapse-item {
    width: 100% !important;
    border-bottom: 1px solid #eeeeee !important;
    transition: 0.3s !important;
    color: #c7c7c7;
}
.collapse_number_wrapper .ant-collapse-header {
    padding: 0 0 40px !important;
    border-radius: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    color: inherit !important;
}
.collapse_number_wrapper .ant-collapse-item {
    border-radius: 0 0 0 0 !important;
}
.collapse_number_wrapper .ant-collapse-header-text {
    font-family: "Geo-400" !important;
    font-size: 28px !important;
    color: inherit !important;
}
@media screen and (max-width: 850px) {
    .collapse_number_wrapper .ant-collapse-header-text {
        font-size: 24px !important;
    }
}
.collapse_number_wrapper .ant-collapse-item-active {
    border-bottom-color: #14161f !important;
    color: #14161f !important;
}

.collapse_number_wrapper .ant-collapse-item-active > .ant-collapse-header {
    padding: 0 0 12px !important;
}

.collapse_number_wrapper .ant-collapse-content-box {
    padding: 0 0 40px 40px !important;
}

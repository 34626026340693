@import "../../../../assets/mixins/mixins.scss";

.title {
    @include font(24px, "Geo-500", var(--m-text));
    text-transform: uppercase;
    align-self: center;
}
.inputs_wrapper {
    @include flColStart();
    width: 290px;
    gap: 22px;
    & > label {
        &:nth-child(3) {
            margin-top: 12.5px;
        }
    }
}
.btns_wrapper {
    @include flexBetween();
    width: 100%;
    .back {
        @include flexCenter();
        @include font(12px, "Geo-400", var(--m-text));
        width: 89px;
        padding: 13px 20px;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 50px;
        border: 1px solid #eee;
        &:hover {
            transform: scale(1.1);
        }
    }
    .create_acc {
        @include flexCenter();
        @include font(12px, "Geo-400", #fff);
        width: 193px;
        padding: 13px 20px;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 50px;
        background: #14161f;
        border: none;
        &:hover {
            transform: scale(1.1);
        }
        &:disabled {
            background: #c7c7c7;
        }
    }
}
.have_acc {
    @include flexCenter();
    gap: 10px;
    width: 100%;
    margin-top: 26px;
    & > span {
        @include font(12px, "Geo-300", var(--m-text));
    }
    & > a {
        @include font(12px, "Geo-300", var(--m-color));
        text-decoration: none;
        text-transform: capitalize;
    }
}

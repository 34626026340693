@import "../../assets/mixins/mixins.scss";

.modals_wrapper {
    @include flexCenter();
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(238, 238, 238, 0.8);
    top: 0;
    left: 0;
    z-index: 999;
    .modal_main {
        @include flexCenter();
        padding: 80px 76px;
        position: relative;
        border-radius: 20px;
        background: #fff;
        @media screen and (max-width: 850px) {
            width: 90%;
            max-height: 85vh;
            overflow-y: auto;
            padding: 40px 20px;
        }
        @media screen and (max-width: 500px) {
            padding: 40px 20px;
        }
        .close {
            @include size(30px, 30px);
            position: absolute;
            cursor: pointer;
            top: 20px;
            right: 20px;
            transition: 0.3s;
            &:hover {
                transform: scale(1.1);
            }
        }
        .system_masq {
            @include flColCenterStart();
            gap: 12px;
            .systems_wrapper {
                @include flexCenter();
                gap: 20px;
                margin-top: 18px;
                @media screen and (max-width: 850px) {
                    flex-direction: column;
                }
                .system_card {
                    @include flColCenterStart();
                    gap: 20px;
                    padding: 30px 28px;
                    border-radius: 20px;
                    border: 1px solid #ebedef;
                    background: #f7f9fb;
                    transition: 0.3s;

                    cursor: pointer;
                    &:hover {
                        transform: scale(1.1);
                    }
                    .texts {
                        @include flColCenterStart();
                        .system {
                            @include font(16px, "Geo-500", var(--m-text));
                        }
                        .about_system {
                            @include font(14px, "Geo-300", #999dab);
                        }
                    }
                    .download {
                        @include font(14px, "Geo-400", var(--m-text));
                        @include flexCenter();
                        padding: 15px 30px;
                        border-radius: 50px;
                        border: 1px solid #14161f;
                        cursor: pointer;
                        transition: 0.2s;
                        text-decoration: none;
                        text-transform: uppercase;
                        &:hover {
                            color: #fff;
                            background-color: var(--m-color);
                            border-color: var(--m-color);
                        }
                    }
                }
            }
        }
        .account {
            @include flColCenterStart();
            gap: 24px;
            .inputs_wrapper {
                @include flColStart();
                gap: 20px;
                width: 290px;
                .input_wrapper {
                    @include font(12px, "Geo-300", var(--m-text));
                    @include flColStart();
                    width: 100%;
                    gap: 4px;
                    position: relative;
                    .label {
                        text-transform: uppercase;
                    }
                    position: relative;
                    .input {
                        @include font(12px, "Geo-300", var(--m-text));
                        @include flexStartCenter();
                        width: 100%;
                        padding: 13px 40px 13px 20px;
                        border-radius: 12px;
                        border: 1px solid #eee;
                        caret-color: var(--m-color);
                        transition: 0.3s;
                        outline: none;
                        &::placeholder {
                            text-transform: none;
                        }
                        &:disabled {
                            background: #f5f5f5;
                            color: #c7c7c7;
                        }
                    }
                    .input:focus + .show_edit {
                        display: none;
                    }
                    .input.error {
                        border-color: #e00252;
                    }
                    .show_edit {
                        position: absolute;
                        cursor: pointer;
                        right: 20px;
                        bottom: 10px;
                    }
                    .error_message {
                        @include font(10px, "Geo-300", #e00252);
                        position: absolute;
                        bottom: -13px;
                    }
                    .saved_info {
                        @include font(12px, "Geo-300", var(--m-color));
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
                .btn {
                    @include flexCenterBetween();
                    width: 100%;
                    padding-bottom: 18px;
                    border: none;
                    border-bottom: 1px solid #ebedef;
                    background: none;
                    cursor: pointer;
                    .btn_icon_name {
                        @include flexCenterStart();
                        @include font(12px, "Geo-300", var(--m-text));
                        gap: 12px;
                    }
                }
            }

            .delete {
                @include font(12px, "Geo-400", #e00252);
                cursor: pointer;
                transition: 0.3s;
                background: none;
                border: none;
                margin-top: 20px;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        .delete_acc {
            @include flColCenterStart();
            gap: 20px;
            .delete_info {
                @include flColCenterStart();
                margin-top: 4px;
                gap: 8px;
            }
        }
        .safety {
            @include flColCenterStart();
            gap: 32px;
            .btns_wrapper {
                @include flColCenterStart();
                gap: 24px;
                width: 315px;
                @media screen and (max-width: 550px) {
                    width: 280px;
                }
                .btn {
                    @include flexCenterBetween();
                    width: 100%;
                    padding-bottom: 24px;
                    border: none;
                    border-bottom: 1px solid #ebedef;
                    background: none;
                    cursor: pointer;
                    &:last-child {
                        border-bottom: none;
                    }
                    .btn_icon_name {
                        @include flexCenterStart();
                        @include font(12px, "Geo-300", var(--m-text));
                        gap: 12px;
                    }
                }
            }
            .end_session {
                @include flexCenter();
                @include font(12px, "Geo-300", #e00252);
                width: 100%;
                padding: 13px 30px;
                cursor: pointer;
                transition: 0.3s;
                border-radius: 50px;
                border: 1px solid #e00252;
                background: transparent;
                &:hover {
                    background: #e00252;
                    color: #fff;
                }
            }
        }
        .end_sessions_wrapper {
            @include flColCenterStart();
            gap: 24px;
        }
        .change_password {
            @include flColCenterStart();
            gap: 24px;
            .inputs_wrapper {
                @include flColStart();
                width: 290px;
                gap: 20px;
                .errors_wrapper {
                    @include flColStart();
                    gap: 12px;
                    .error_rows {
                        @include font(12px, "Geo-300", var(--m-text));
                        @include flexCenterStart();
                        gap: 8px;
                    }
                }
            }
        }
        .change_email_wrapper {
            @include flColCenterStart();
            gap: 24px;
        }
        .qr_code_info_wrapper {
            @include flColCenterStart();
            gap: 24px;
        }
        .convert_subs {
            @include flColCenterStart();
            gap: 24px;
            max-width: 290px;
        }
    }
    .big_border {
        @include size(92px, 92px);
        @include flexCenter();
        border-radius: 50%;
        border: 1px solid #8061fa0d;
        .smallBorder {
            @include size(72px, 72px);
            @include flexCenter;
            border-radius: 50%;
            border: 1px solid #8061fa0d;
            .icon_wrapper {
                @include size(52px, 52px);
                @include flexCenter();
                border-radius: 50%;
                background: linear-gradient(170deg, #ffffff, #6742fbcc, #b7a7f7, #ffffff);
            }
        }
    }
    .red_btn {
        @include flexCenter();
        @include font(14px, "Geo-400", #c7c7c7);
        width: 100%;
        padding: 11px 30px;
        border-radius: 50px;
        border: 1px solid #ebedef;
        cursor: pointer;
        transition: 0.3s;
        background: none;
        &:hover {
            background: #e00252;
        }
    }
    .black_btn {
        @include flexCenter();
        @include font(14px, "Geo-400", #fff);
        text-transform: uppercase;
        width: 100%;
        max-width: 290px;
        padding: 11px 30px;
        border-radius: 50px;
        background: #14161f;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            transform: scale(1.1);
        }
        &:disabled {
            background: #c7c7c7;
        }
    }
    .blat_text_btn {
        @include font(12px, "Geo-400", var(--m-text));
        background: none;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        text-transform: uppercase;
        &:hover {
            transform: scale(1.1);
        }
    }
    .info {
        @include flColCenterStart();
        gap: 12px;
    }
    .subTitle {
        @include font(12px, "Geo-300", #999dab);
        text-transform: uppercase;
        text-align: center;
    }
    .title {
        @include font(28px, "Geo-400", var(--m-text));
        text-transform: uppercase;
        text-align: center;
        @media screen and (max-width: 550px) {
            font-size: 24px;
        }
    }
}

.tfa_main_wrapper {
    @include flColCenterStart();
    width: 492px;
    border-radius: 16px;
    box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 550px) {
        width: 90%;
    }
    .tfa_close_icon {
        @include flexCenter();
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .tfa_icon_place {
        @include size(100%, 139px);
        @include flexCenter();
        background: #4c85f6;
        & > img {
            @include size(74px, 74px);
            object-fit: contain;
        }
    }
    .tfa_info_place {
        @include flColCenterStart();
        margin: 32px 0 30px 0;
        width: calc(100% - 50px);
        .tfa_header {
            @include font(18px, "Geo-500", #292929);
            font-weight: 500;
            align-self: flex-start;
            line-height: 14px;
        }
        .tfa_docs {
            @include flColStart();
            margin-top: 28px;
            @media screen and (max-width: 550px) {
                padding-left: 50px;
            }
            & > li {
                @include font(14px, "Geo-400", #808080);
                padding-left: 15px;
                margin-left: -60px;
                list-style: none;
                font-weight: 400;
                list-style-position: inside;
                position: relative;
                line-height: 20px;
                white-space: nowrap;
                @media screen and (max-width: 550px) {
                    white-space: wrap;
                }
                &:before {
                    content: "•";
                    position: absolute;
                    border-radius: 50%;
                    left: 0;
                }
            }
        }
        .tfa_code_place {
            @include flexCenter();
            width: 100%;
            margin-top: 24px;
            @media screen and (max-width: 550px) {
                margin-top: 10px;
            }
            .tfa_qr_code {
                @include flColCenterStart();
                background-color: #fff;
                border-radius: 10px;
                width: 157px;
                gap: 4px;
                margin-bottom: 33px;
                @media screen and (max-width: 550px) {
                    margin-bottom: 20px;
                }
                .tfa_qr_img_wrapper {
                    @include flexCenter();
                    height: 154px;
                    & > img {
                        @include flexCenter();
                        width: 137px;
                        height: 134px;
                        object-fit: contain;
                    }
                }
                .tfa_can_not_scan {
                    @include font(12px, "Geo-500", #4c85f6);
                    @include flexCenter();
                    border: none;
                    background: none;
                    font-weight: 500;
                    cursor: pointer;
                    white-space: nowrap;
                }
            }
            .tfa_security_key_wrapper {
                @include flexCenterBetween();
                padding-right: 16px;
                width: 100%;
                margin: 16px 0 35px 0;
                @media screen and (max-width: 550px) {
                    flex-direction: column;
                }
                .tfa_security_key {
                    @include flexCenterStart();
                    gap: 10px;

                    & > p {
                        @include font(20px, "Geo-400", #292929);
                        font-weight: 300;
                    }
                    .tfa_copy_icon {
                        @include flexCenter();
                        position: relative;

                        cursor: pointer;
                        & > svg {
                            color: #808080;
                            width: 15px;
                        }
                        .copied {
                            @include font(10px, "Geo-400", #808080);
                            position: absolute;
                            bottom: -15px;
                            white-space: nowrap;
                        }
                    }
                }
                & > button {
                    @include font(12px, "Geo-500", #4c85f6);
                    background: none;
                    border: none;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
        .tfa_btn_place {
            @include flexCenterBetween();
            margin-top: 35px;
            width: 100%;
            @media screen and (max-width: 550px) {
                margin-top: 10px;
            }
            & > button {
                @include font(14px, "Geo-400", #4c85f6);
                font-weight: 400;
                border: none;
                background: none;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
        .tfa_verify_page {
            @include flColCenterStart();
            width: 100%;
            margin-top: 28px;
            .tfa_verify_text {
                @include font(14px, "Geo-400", #808080);
                align-self: flex-start;
                line-height: 20px;
            }
            .tfa_verify_code_place {
                margin-top: 36px;
            }
            .tfa_verify_btn {
                @include flexCenterStart();
                gap: 28px;
                margin-top: 15px;
                .tfa_verify_save {
                    @include size(177px, 42px);
                    @include font(14px, "Geo-400", #fff);
                    border-radius: 5px;
                    background: #4c85f6;
                    border: none;
                    cursor: pointer;
                    &:disabled {
                        background: #808080;
                    }
                }
                .tfa_veirfy_page_cancel {
                    @include font(14px, "Geo-400", #4c85f6);
                    cursor: pointer;
                    border: none;
                    background: none;
                }
            }
        }
    }
}

.vi__wrapper input::placeholder {
    color: #fff !important;
    background: #fff !important;
}

.vf_container {
    width: 200px;
}

.vf_character {
    border: 1px solid #e8ebed;
    border-radius: 5px !important;
    font-size: 14px;
    text-transform: uppercase;
    height: 30px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vf_character.active {
    border-color: rgba(229, 0, 0, 0.6) !important;
    background: rgba(229, 0, 0, 0.05);
    animation: shake 0.7s forwards;
}
.vf_character_selected {
    outline-color: var(--m-c);
    color: var(--m-c);
}

.vf_character_inactive {
    background-color: #fff;
}

@keyframes shake {
    0% {
        transform: rotate(0);
    }
    10% {
        transform: rotate(10deg);
    }
    20% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(-10deg);
    }
    40% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0);
    }
    70% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(0);
    }
    90% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}

@import "../../../../assets/mixins/mixins.scss";

.title {
    @include font(24px, "Geo-500", var(--m-text));
    text-transform: uppercase;
    align-self: center;
}

.subTitle {
    @include font(12px, "Geo-300", #c7c7c7);
    text-align: center;
    max-width: 290px;
}

.verif_inp {
    @include flexCenter();
    width: 100%;
}

.btns_wrapper {
    @include flexBetween();
    width: 100%;
    .back {
        @include flexCenter();
        @include font(12px, "Geo-400", var(--m-text));
        width: 89px;
        padding: 13px 20px;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 50px;
        border: 1px solid #eee;
        &:hover {
            transform: scale(1.1);
        }
    }
    .confirm {
        @include flexCenter();
        @include font(12px, "Geo-400", #fff);
        width: 193px;
        padding: 13px 20px;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 50px;
        background: #14161f;
        border: none;
        &:hover {
            transform: scale(1.1);
        }
        &:disabled {
            background: #c7c7c7;
        }
    }
}

.vi__wrapper input::placeholder {
    color: #fff !important;
    background: #fff !important;
}

.vf_container {
    margin-bottom: 40px;
    width: 190px;
    height: 30px;
}

.vf_character {
    border: 1px solid #e8ebed;
    border-radius: 5px !important;
    font-size: 14px;
    font-family: "Geo-400";
    text-transform: uppercase;
    height: 30px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vf_character.active {
    border-color: rgba(229, 0, 0, 0.6) !important;
    background: rgba(229, 0, 0, 0.05);
    animation: shake 0.7s forwards;
}
.vf_character_selected {
    outline-color: var(--m-c);
    color: var(--m-c);
}

.vf_character_inactive {
    background-color: #fff;
}

@keyframes right_to_left {
    0% {
        right: -50%;
    }
    100% {
        right: 0;
    }
}
@keyframes left_to_right {
    0% {
        right: 0;
    }
    100% {
        right: -50%;
    }
}
@keyframes shake {
    0% {
        transform: rotate(0);
    }
    10% {
        transform: rotate(10deg);
    }
    20% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(-10deg);
    }
    40% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0);
    }
    70% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(0);
    }
    90% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}

@import "../../../../assets/mixins/mixins.scss";

.pay_for_sub_wrapper {
    @include flColStart();
    width: 100%;
    max-width: var(--m-width);
    gap: 32px;
    padding: 12px 15px 22px;
    min-height: calc(100vh - 122px);
    .pay_for_sub_main {
        @include flexBetween();
        width: 100%;
        .title_subtitle {
            @include flColStart();
            gap: 12px;
            .back_btn {
                @include flexCenter();
                gap: 6px;
                text-decoration: none;
                color: #292929;
                cursor: pointer;
                & > svg {
                    transform: rotate(180deg);
                }
                & > span {
                    @include font(12px, "Geo-400", #c7c7c7);
                    font-weight: 400;
                    transition-duration: 200ms;
                }
            }
            .subtitle {
                @include font(12px, "Geo-300", #999dab);
            }
            .title {
                @include font(44px, "Geo-400", var(--m-text));
                text-transform: uppercase;
            }
        }
    }
    .sub_payment_wrapper {
        @include flexCenterBetween();
        width: 100%;
        border-radius: 20px;
        background: #f6f8fa;
        @media screen and (max-width: 850px) {
            flex-direction: column;
        }
        .payment_left_side {
            @include flColStart();
            width: 59%;
            padding: 40px;
            gap: 28px;
            @media screen and (max-width: 850px) {
                width: 100%;
            }
            .title_subtitle {
                @include flColStart();
                gap: 12px;
                .subtitle {
                    @include font(12px, "Geo-300", #999dab);
                    text-transform: uppercase;
                }
                .title {
                    @include font(28px, "Geo-400", var(--m-text));
                    text-transform: uppercase;
                }
            }
            .seats_wrapper {
                @include flColStart();
                gap: 4px;
                .seats_label {
                    @include font(12px, "Geo-400", var(--m-text));
                    text-transform: uppercase;
                }
                .seats_input_wrapper {
                    @include flexCenterBetween();
                    padding: 13px 20px;
                    width: 230px;
                    border-radius: 12px;
                    border: 1px solid #ebedef;
                    background: #fff;
                    .plus_minus {
                        @include font(16px, "Geo-400", #c7c7c7);
                        cursor: pointer;
                    }
                    .seats {
                        @include font(12px, "Geo-300", var(--m-text));
                    }
                }
            }
            .pay_method_wrapper {
                @include flColStart();
                gap: 10px;
                .title {
                    @include font(12px, "Geo-400", var(--m-text));
                    text-transform: uppercase;
                }
            }
        }
        .right_side {
            @include flColCenterStart();
            gap: 20px;
            width: 38%;
            border-radius: 0px 20px 20px 0px;
            background: #7b4ff7;
            padding: 28px 40px 32px;
            position: relative;
            height: 365px;

            @media screen and (max-width: 850px) {
                width: 100%;
                border-radius: 0px 0px 20px 20px;
            }
            .subtitle_title {
                @include flColStart();
                width: 100%;
                gap: 14px;
                .title {
                    @include font(28px, "Geo-500", #fff);
                    text-transform: uppercase;
                }
                .price_subtitle {
                    @include flexCenterBetween();
                    width: 100%;
                    .sub_cost {
                        @include font(14px, "Geo-300", #d6c7ff);
                    }
                    .price {
                        @include font(24px, "Geo-400", #fff);
                        @include flexCenter();
                        gap: 5px;
                        .discounted {
                            @include font(14px, "Geo-300", #d6c7ff);
                            text-decoration: line-through;
                        }
                    }
                }
            }
            .promo_wrapper {
                @include flColStart();
                gap: 4px;
                width: 100%;
                .label {
                    @include font(12px, "Geo-400", #fff);
                    text-transform: uppercase;
                }
                .inp_wrapper {
                    @include flexCenter();
                    width: 100%;
                    position: relative;
                    & > input {
                        @include flexCenterStart();
                        @include font(12px, "Geo-300", var(--m-text));
                        padding: 13px 40px 13px 20px;
                        width: 100%;
                        border-radius: 12px;
                        background: #fff;
                        outline: none;
                        border: none;
                    }
                    & > svg {
                        position: absolute;
                        right: 20px;
                    }
                    .activate_btn {
                        @include flexCenter();
                        @include font(12px, "Geo-300", #fff);
                        position: absolute;
                        text-transform: uppercase;
                        border-radius: 20px;
                        padding: 10px;
                        cursor: pointer;
                        background: #14161f;
                        border: none;
                        right: 5px;
                    }
                }
            }
            .info_wrapper {
                @include flColStart();
                gap: 3px;
                & > li {
                    @include font(12px, "Geo-300", #d6c7ff);
                    list-style-position: outside;
                    margin-left: 10px;
                }
            }
            .btn {
                @include flexCenter();
                @include font(14px, "Geo-400", #fff);
                width: 100%;
                padding: 15px 30px;
                border: none;
                border-radius: 50px;
                background: #14161f;
                cursor: pointer;
                transition: 0.3s;
                text-transform: uppercase;
                &:hover {
                    transform: scale(1.05);
                }
                &:disabled {
                    background-color: #c7c7c7;
                    cursor: not-allowed;
                }
            }
            .use_qr_btn {
                @include font(12px, "Geo-400", #fff);
                background: none;
                border: none;
                cursor: pointer;
                position: absolute;
                bottom: 10px;
            }
        }
    }
}

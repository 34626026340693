@import "../../assets/mixins/mixins.scss";

.button_wrapper {
    @include size(169px, 169px);
    @include flexCenter();
    position: relative;
    @media screen and (max-width: 550px) {
        width: 167px;
        height: 48px;
    }

    .button {
        @include size(149px, 149px);
        @include font(16px, "Geo-400", var(--white));
        @include flexCenter();
        text-align: center;
        padding: 20px;
        text-transform: uppercase;
        background: #14161f;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: none;
        text-decoration: none;
        &:hover {
            background: var(--m-color);
        }
        &:active {
            transform: scale(0.8);
        }
        &:hover + .border {
            transform: rotate(90deg);
            border-color: var(--m-color);
            border-right-color: transparent;
        }
        @media screen and (max-width: 550px) {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            font-size: 14px;
        }
    }
    .border {
        @include size(169px, 169px);
        border: 1px solid #14161f;
        border-right-color: transparent;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        transition: 0.3s;
        transform: rotate(45deg);
        @media screen and (max-width: 550px) {
            display: none;
        }
    }
}

// Geologica Roman( 100 )

@font-face {
    font-family: "Geo-100"; // Re
    src: url("./Geologica/Geologica-Thin.ttf");
}

// Geologica Roman( 200 )

@font-face {
    font-family: "Geo-200"; // Re
    src: url("./Geologica/Geologica-ExtraLight.ttf");
}

// Geologica Roman( 300 )

@font-face {
    font-family: "Geo-300"; // Re
    src: url("./Geologica/Geologica-Light.ttf");
}

// Geologica Roman( 400 )

@font-face {
    font-family: "Geo-400"; // Re
    src: url("./Geologica/Geologica-Regular.ttf");
}

// Geologica Roman( 500 )

@font-face {
    font-family: "Geo-500"; // Re
    src: url("./Geologica/Geologica-Medium.ttf");
}

// Geologica Roman( 600 )

@font-face {
    font-family: "Geo-600"; // Re
    src: url("./Geologica/Geologica-SemiBold.ttf");
}

// Geologica Roman( 700 )

@font-face {
    font-family: "Geo-700"; // Re
    src: url("./Geologica/Geologica-Bold.ttf");
}

// Geologica Roman( 800 )

@font-face {
    font-family: "Geo-800"; // Re
    src: url("./Geologica/Geologica-ExtraBold.ttf");
}

// Geologica Roman( 900 )

@font-face {
    font-family: "Geo-900"; // Re
    src: url("./Geologica/Geologica-Black.ttf");
}

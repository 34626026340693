@import "../../../assets/mixins/mixins.scss";

.tariff_wrapper {
    @include flColCenterStart();
    .title_main {
        @include flexBetween();
        width: 100%;
        max-width: var(--m-width);
        padding: 12px 15px 22px;
        @media screen and (max-width: 850px) {
            flex-wrap: wrap;
        }
        .title_subtitle {
            @include flColStart();
            gap: 12px;
            .subtitle {
                @include font(12px, "Geo-300", #999dab);
            }
            .title {
                @include font(44px, "Geo-400", var(--m-text));
                text-transform: uppercase;
            }
        }
        .tariff_logo {
            @include flexCenterStart();
            gap: 16px;
            padding: 16px;
            border-radius: 20px;
            background: #f6f8fa;
            @media screen and (max-width: 550px) {
                width: 100%;
            }
            .icon {
                @include size(52px, 52px);
                @include flexCenter();
                border-radius: 50%;
                background: linear-gradient(140deg, #ffffff, #6742fbcc, #b7a7f7, #ffffff);
            }
            .tariff_info {
                @include flColStartCenter();
                gap: 6px;
                .tariff_plan {
                    @include font(12px, "Geo-300", #c7c7c7);
                    text-transform: uppercase;
                }
                .tariff {
                    @include font(16px, "Geo-500", var(--m-text));
                    text-transform: uppercase;
                }
            }
        }
    }
    .sub_plans_wrapper {
        @include flexCenter();
        width: 100%;
        padding: 16px 15px 30px;
        background: linear-gradient(180deg, #f7f9fb 0%, #fff 100%);

        .sub_plans_main {
            @include flColCenterStart();
            gap: 29px;
            width: 100%;
            max-width: var(--m-width);
            // .sub_switcher {
            //     @include flexCenterBetween();
            //     width: 486px;
            //     height: 64px;
            //     border-radius: 50px;
            //     border: 1px solid #ebedef;
            //     background: #fff;
            //     padding: 8px;
            //     max-width: 600px;
            //     position: relative;
            //     .switcher_date {
            //         @include flexCenter();
            //         width: 50%;
            //         height: 100%;
            //         cursor: pointer;
            //         position: relative;
            //         z-index: 2;
            //         & > span {
            //             @include font(14px, "Geo-400", var(--m-text));
            //             text-transform: uppercase;
            //         }
            //         .active_billing {
            //             color: #fff;
            //         }
            //         .save_to_up_info {
            //             color: #dbceff;
            //         }
            //     }
            //     .switcher_clicked_btn {
            //         position: absolute;
            //         z-index: 1;
            //         width: calc(50% - 6px);
            //         height: calc(100% - 6px);
            //         border-radius: 50px;
            //         background: var(--1, linear-gradient(180deg, #7b4ff7 0%, #6936f5 100%));
            //         left: 3px;
            //         transition: 200ms ease-in-out;
            //     }
            //     .switcher_clicked_btn.yearly {
            //         left: calc(50% + 3px);
            //     }
            // }
            .sub_plans {
                @include flexCenterBetween();
                width: 100%;
                @media screen and (max-width: 850px) {
                    justify-content: center;
                    flex-wrap: wrap;
                }
                .subscription {
                    @include flexCenter();
                    padding: 28px;
                    width: 100%;
                    min-height: 350px;
                    border: 1px solid #ebedef;
                    background: #f7f9fb;
                    cursor: pointer;
                    &:first-child {
                        border-radius: 20px 0px 0px 20px;
                    }
                    &:last-child {
                        border-radius: 0px 20px 20px 0px;
                    }
                    .sub_main {
                        @include flColCenterBetween();
                        gap: 20px;
                        width: 100%;
                        &:hover {
                            .change_buy_btn {
                                transform: scale(1.04);
                            }
                        }
                        .name_price {
                            @include flColStart();
                            width: 100%;
                            gap: 4px;
                            .name {
                                @include font(28px, "Geo-500", var(--m-text));
                                text-transform: uppercase;
                                @media screen and (max-width: 850px) {
                                    font-size: 24px;
                                }
                            }
                            .price {
                                @include font(14px, "Geo-300", #999dab);
                            }
                        }
                        .profiles_info {
                            @include flColStart();
                            width: 100%;
                            gap: 8px;
                            list-style-position: inside;
                            .unlim_prof {
                                @include font(14px, "Geo-400", var(--m-text));
                            }
                            .each_profile {
                                @include font(14px, "Geo-300", var(--m-text));
                            }
                        }
                        .change_buy_btn {
                            @include flexCenter();
                            @include font(14px, "Geo-400", var(--m-text));
                            justify-self: flex-end;
                            padding: 15px 30px;
                            width: 100%;
                            border-radius: 50px;
                            border: 1px solid #14161f;
                            transition: 0.3s;
                            background: transparent;
                            cursor: pointer;
                        }
                    }
                }
                .subscription.pro_sub {
                    border-radius: 20px;
                    background: #7b4ff7;
                    min-height: 400px;
                    .sub_main {
                        .unlim_prof,
                        .each_profile,
                        .name {
                            color: #fff;
                        }
                        .price {
                            color: #d6c7ff;
                        }
                        .change_buy_btn {
                            background: #14161f;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

@import "../../assets/mixins/mixins.scss";

.back_btn {
    @include flexCenter();
    gap: 6px;
    text-decoration: none;
    color: #292929;
    transition-duration: 200ms;
    & > svg {
        transform: rotate(180deg);
    }
    & > span {
        @include font(12px, "Geo-400", #c7c7c7);
        font-weight: 400;
        transition-duration: 200ms;
    }
    &:hover {
        color: var(--m-c);
    }
}

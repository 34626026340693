.collapse_righ_icon {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.collapse_righ_icon .ant-collapse-item {
    border-bottom: 1px solid #14161f !important;
    transition: 0.3s !important;
    color: #14161f;
}
.collapse_righ_icon .ant-collapse-header {
    width: 100% !important;
    padding: 0 0 40px !important;
    border-radius: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    color: inherit !important;
    text-transform: uppercase !important;
}

.collapse_righ_icon .ant-collapse-item {
    border-radius: 0 0 0 0 !important;
}

.collapse_righ_icon .ant-collapse-header-text {
    width: none;
    font-family: "Geo-400" !important;
    font-size: 28px !important;
    color: inherit !important;
    display: flex;
    order: 1;
}
@media screen and (max-width: 850px) {
    .collapse_righ_icon .ant-collapse-header-text {
        font-size: 18px !important;
    }
}

.expend_right_icon {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
    background: #14161f;
    color: #ffffff !important;
    display: flex !important;
    justify-content: center;
    order: 2 !important;
    padding: 0;
    padding-bottom: 2px;
}

.collapse_righ_icon .ant-collapse-expand-icon {
    display: flex;
    order: 2;
}

.collapse_righ_icon .ant-collapse-item-active > .ant-collapse-header {
    padding: 0 0 12px !important;
}

.collapse_righ_icon .ant-collapse-content-box {
    font-size: 16px;
    font-family: "Geo-300";
    color: var(--m-text);
    padding: 0 0 40px 0 !important;
}

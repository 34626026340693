@import "../../../../assets/mixins/mixins.scss";

.forget_about_wrapper {
    @include flexStartCenter();
    width: 100%;
    height: 720px;
    position: relative;
    @media screen and (max-width: 1240px) {
        height: 970px;
        flex-direction: column;
        justify-content: start;
    }
    @media screen and (max-width: 550px) {
        height: 700px;
        flex-direction: column;
    }
    .forget_about_main {
        height: 100%;
        @include flexCenterStart();
        position: relative;
        width: 100%;
        max-width: var(--m-width);
        margin-bottom: 91px;
        z-index: 2;
        @media screen and (max-width: 1240px) {
            flex-direction: column;
        }
        @media screen and (max-width: 550px) {
            padding-top: 90px;
        }
        .text_title_wrapper {
            max-width: 841px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1240px) {
                flex-direction: column;
                padding: 0 15px;
            }
            .title_wrapper {
                max-width: 477px;
                @media screen and (max-width: 1240px) {
                    max-width: 95%;
                }
            }
            .forget_text {
                @include font(16px, "Geo-300", var(--m-text));
                max-width: 363px;
                text-align: end;
                text-shadow: 0 0 1px white;
                @media screen and (max-width: 550px) {
                    font-size: 14px;
                }
            }
        }
    }
    .images_wrapper {
        @include flColEndStart();
        position: absolute;
        top: 0;
        right: 0;
        gap: 12px;
        @media screen and (max-width: 1240px) {
            position: initial;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .first_lane {
            @include flexStartStart();
            gap: 12px;
            & > div {
                &:nth-child(1) {
                    @include size(30vw, 207px);
                    background: url("../../../../assets/images/forgetAbout/first.png");
                    background-size: cover;
                    background-position: bottom left;
                    @media screen and (max-width: 1240px) {
                        width: 37vw;
                    }
                    @media screen and (max-width: 550px) {
                        height: 130px;
                    }
                }
                &:nth-child(2) {
                    @include size(16.38vw, 207px);
                    position: relative;
                    background: url("../../../../assets/images/forgetAbout/second.png");
                    background-size: cover;
                    background-position: bottom left;
                    z-index: -2;
                    @media screen and (max-width: 1240px) {
                        display: none;
                    }
                }
            }
        }
        .second_line {
            @include size(30vw, 256px);
            background: url("../../../../assets/images/forgetAbout/third.png");
            background-size: cover;
            background-position: top left;
            @media screen and (max-width: 1240px) {
                width: 60vw;
                height: 207px;
            }
            @media screen and (max-width: 550px) {
                height: 130px;
            }
        }
        .third_line {
            @include flexStartStart();
            gap: 12px;
            & > div {
                &:nth-child(1) {
                    @include size(30vw, 235px);
                    background: url("../../../../assets/images/forgetAbout/forth.png");
                    background-size: cover;
                    background-position: top left;
                    @media screen and (max-width: 1240px) {
                        width: 48vw;
                        height: 207px;
                    }
                    @media screen and (max-width: 550px) {
                        height: 130px;
                    }
                }
                &:nth-child(2) {
                    @include size(25.7vw, 235px);
                    background: url("../../../../assets/images/forgetAbout/fifth.png");
                    background-size: cover;
                    background-position: top left;
                    @media screen and (max-width: 1240px) {
                        width: 48vw;
                        height: 207px;
                    }
                    @media screen and (max-width: 550px) {
                        height: 130px;
                    }
                }
            }
        }
    }
}

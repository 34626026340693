.grainy {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/grainy.png");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

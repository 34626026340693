@import "../../assets/mixins/mixins.scss";

.advantages_title {
    @include font(95px, "Geo-400", #fff);
    text-transform: uppercase;
    text-align: center;
    & > span {
        color: #14161f;
        text-shadow: -0.7px 0.7px 0 #fff, 0.7px 0.7px 0 #fff, 0.7px -0.7px 0 #fff, -0.7px -0.7px 0 #fff;
    }
    @media screen and (max-width: 850px) {
        font-size: 75px;
    }
    @media screen and (max-width: 750px) {
        font-size: 60px;
    }
    @media screen and (max-width: 550px) {
        font-size: 44px;
    }
}

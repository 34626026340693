.subtitle {
    color: #c7c7c7;
    font-family: "Geo-300";
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    @media screen and (max-width: 550px) {
        font-size: 12px !important;
    }
}

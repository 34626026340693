@import "../../assets/mixins/mixins.scss";

.main_header {
    @include flexCenter();
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    padding: 14px 0;
    transition: 0.3s;
    .header_wrapper {
        @include flexCenterBetween();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        .nav_sign_in_wrapper {
            @include flexCenter();
            gap: 32px;
            @media screen and (max-width: 680px) {
                gap: 10px;
            }
            .main_nav {
                & > ul {
                    @include flexCenter();
                    gap: 32px;
                    & > li {
                        @include font(14px, "Geo-300", var(--m-text));
                        list-style: none;
                        text-transform: uppercase;
                        cursor: pointer;
                        & > a {
                            text-decoration: none;
                            color: inherit;
                        }
                    }
                }
                @media screen and (max-width: 680px) {
                    & > ul {
                        display: none;
                    }
                }
            }
            .router_sign_in {
                @include flexCenter();
                text-decoration: none;
                .sign_in {
                    @include font(14px, "Geo-400", var(--white));
                    padding: 11px 20px;
                    text-transform: uppercase;
                    border-radius: 50px;
                    background: #14161f;
                }
                .user_icon {
                    @include size(46px, 46px);
                    @include flexCenter();
                    @include font(16px, "Geo-400", #fff);
                    border: 1px solid #fff;
                    background: var(--1, linear-gradient(180deg, #7b4ff7 0%, #6936f5 100%));
                    border-radius: 50%;
                }
            }
            .languages {
                @include font(14px, "Geo-300", var(--m-text));
                text-transform: uppercase;
                cursor: pointer;
                @media screen and (max-width: 680px) {
                    order: -1;
                }
            }
            .hamburger_wrapper {
                @include size(40px, 40px);
                @include flexCenter();
                position: relative;
                display: none;
                transition: 0.3s;
                @media screen and (max-width: 680px) {
                    display: flex;
                }
                .menu_btn {
                    @include flexCenter();
                    @include size(40px, 40px);
                    border-radius: 50px;
                    background: #eee;
                    &:after {
                        @include flexCenter();
                        @include font(16px, "Geo-400", white);
                        content: "x";
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        background: var(--m-color);
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        transition: 0.3s;
                        opacity: 0;
                    }
                }

                .forToggle {
                    @include size(40px, 40px);
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    &:checked ~ .hamburger_background {
                        z-index: 99;
                        display: flex;
                    }
                    &:checked ~ .menu_btn::after {
                        opacity: 1;
                    }
                }

                .hamburger_background {
                    @include flColStart();
                    position: fixed;
                    gap: 40px;
                    width: 100%;
                    height: 100vh;
                    background: #fff;
                    padding: 32px 15px;
                    left: 0;
                    top: 73px;
                    display: none;
                    transition: 0.3s;
                    .hamburger_main {
                        @include flColStart();
                        width: 100%;
                        gap: 32px;
                        .hamburger_item {
                            @include font(48px, "Geo-300", var(--m-text));
                            text-transform: uppercase;
                            list-style: none;
                            & > label {
                                & > a {
                                    text-decoration: none;
                                    color: var(--m-text);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.main_header.scrolled {
    background-color: #c8c8c8b7;
}

.lng_name {
    font-family: "Geo-300";
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    &:disabled {
        color: #c8c8c8b7;
    }
    & > img {
        width: 20px;
        height: auto;
        object-fit: contain;
    }
}

.select {
    & * {
        border-color: #c7c7c7 !important;
    }
}

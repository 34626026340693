@import "../../assets/mixins/mixins.scss";

.sign_up_wrapper {
    @include flexStartCenter();
    width: 100%;
    min-height: 100vh;
    background: #14161f;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        padding: 15px;
    }
    @media screen and (max-width: 500px) {
        align-items: flex-start;
    }
    .signUp_left {
        @include flexStartCenter();
        width: 55%;
        padding-left: 20px;
        @media screen and (max-width: 800px) {
            padding: 0;
        }
        .signUp_left_main {
            width: 100%;
            max-width: 554px;
            @include flColStart();
            margin-top: 20px;
            gap: 177px;
            @media screen and (max-width: 800px) {
                gap: 40px;
            }

            .info_wrapper {
                @include flColStart();
                gap: 17px;
                .info {
                    @include font(14px, "Geo-300", #fff);
                    margin-top: 10px;
                    max-width: 240px;
                }
            }
        }
    }
    .signUp_right {
        @include flexCenter();
        width: 45%;
        min-height: 100vh;
        background: #fff;
        justify-self: flex-end;
        @media screen and (max-width: 1050px) {
            padding: 199px 20px 0 50px;
        }
        @media screen and (max-width: 800px) {
            width: 70%;
            min-height: initial;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            padding: 44px 20px 32px;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        .sign_up_right_main {
            @include flColStart();
            gap: 24px;
        }
    }
}

@import "../../../assets/mixins/mixins.scss";

.private_header {
    @include flexCenter();
    width: 100%;
    border-bottom: 1px solid #e8eaee;
    margin-bottom: 20px;
    .header_main {
        @include flexBetween();
        width: 100%;
        max-width: var(--m-width);
        padding: 12px 15px;
        & > a {
            @media screen and (max-width: 550px) {
                transform: scale(0.8);
            }
        }
        .balance_info {
            @include flexCenter();
            gap: 12px;
            .balance {
                @include flexCenter();
                gap: 8px;
                @include font(14px, "Geo-400", var(--m-text));
                padding: 11px 13px;
                border-radius: 32px;
                border: 1px solid #e8eaee;
            }
            .userInfo {
                @include flexCenter();
                gap: 12px;
                padding: 6px 12px 6px 6px;
                border-radius: 32px;
                border: 1px solid #e8eaee;
                @media screen and (max-width: 550px) {
                    padding: 6px;
                }
                .user_icon {
                    @include size(26px, 26px);
                    @include flexCenter();
                    @include font(12px, "Geo-400", #fff);
                    text-transform: capitalize;
                    border: 1px solid #fff;
                    background: var(--1, linear-gradient(180deg, #7b4ff7 0%, #6936f5 100%));
                    border-radius: 50%;
                }
                .user_name {
                    @include font(14px, "Geo-400", var(--m-text));
                    @media screen and (max-width: 550px) {
                        display: none;
                    }
                }
            }
            .logout {
                @include flexCenter();
                cursor: pointer;
                border: none;
                background: none;
            }
        }
    }
}

@import "../../../assets//mixins/mixins.scss";

.private_footer {
    @include flexCenter();
    width: 100%;
    border-top: 1px solid #e8eaee;
    padding: 20px 0;
    margin-top: 20px;
    .footer_date {
        @include flexBetween();
        width: 100%;
        max-width: var(--m-width);
        padding: 0 15px;
        .date {
            @include font(12px, "Geo-300", #c7c7c7);
        }
        .links {
            @include flexCenter();
            gap: 25px;
            & > a {
                @include font(12px, "Geo-300", #c7c7c7);
                text-decoration: none;
            }
        }
    }
}

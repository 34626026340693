@import "../../../../../assets/mixins/mixins.scss";

.currencies_carousel_wrapper {
    @include flexCenterStart();
    width: 100%;
    height: 100%;
    gap: 16px;
    position: relative;
    .carouser_is_selected {
        border: 1.5px solid #560bad;
    }

    .animated_process {
        box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0) !important;
        border: none !important;
        & > img {
            opacity: 0 !important;
        }
    }

    .carouser_item {
        @include size(144px, 75px);
        @include flexCenter();
        min-width: 144px;
        flex-shrink: 1;
        border-radius: 10px;
        background: #fff;
        box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.3);
        cursor: pointer;

        & > img {
            @include font(14px, "Geo-400", var(--m-text));
            @include flexCenter();
            @include size(65%, 65%);
            object-fit: contain;
        }
        .animation {
            @include size(144px, 75px);
            @include flexCenter();
            border-radius: 10px;
            background: #fff;
            position: absolute;
            animation: move_to_left 300ms forwards;
            border: 1.5px solid #560bad;
            & > img {
                @include font(14px, "Geo-400", var(--m-text));
                @include flexCenter();
                @include size(65%, 65%);
                object-fit: contain;
            }
        }
    }
    .arrow_btn {
        @include size(30px, 30px);
        @include flexCenter();
        min-width: 30px;
        min-height: 30px;
        background: #fff;
        border-radius: 50%;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
    .arrow_btn.right {
    }
    .arrow_btn.left {
        position: absolute;
        left: -35px;
        & > svg {
            transform: rotate(180deg);
        }
    }
}

@keyframes move_to_left {
    0% {
        left: 0;
    }
    100% {
        left: var(--i);
    }
}

@import "../../../../assets/mixins/mixins.scss";

.antdtidetected_wrapper {
    @include flColCenterStart();
    padding: 72px 0 45px;
    width: 100%;
    position: relative;
    .antdtidetected_main {
        @include flColCenterStart();
        width: 100%;
        max-width: var(--m-width);
        gap: 36px;
        padding: 0 15px;
        .animated_blocks {
            @include flexBetween();
            width: 100%;
            @media screen and (max-width: 800px) {
                flex-direction: column;
                align-items: flex-end;
                gap: 19px;
            }
            .gray_line {
                width: 100%;
                max-width: 634px;
                height: 1px;
                background: #eee;
            }
            .right_blocks {
                @include flColEndStart();
                position: relative;
                z-index: -2;
                .up_blocks {
                    @include size(125px, 125px);
                    @include flexCenter();
                    background: #eee;
                    @media screen and (max-width: 550px) {
                        @include size(76.807px, 76.807px);
                    }
                    .rounded {
                        @include size(97px, 97px);
                        border-radius: 50%;
                        background: var(--white);
                        @media screen and (max-width: 550px) {
                            @include size(59.602px, 59.602px);
                        }
                    }
                }
                .down_blocks {
                    @include flexStartStart();
                    padding-right: 125px;
                    gap: 12px;
                    @media screen and (max-width: 550px) {
                        padding-right: 77px;
                    }
                    & > div {
                        height: 125px;
                        background: #eee;
                        @media screen and (max-width: 550px) {
                            height: 76.807px;
                        }
                    }
                    .small {
                        width: 3px;
                    }
                    .medium {
                        width: 6px;
                    }
                    .large {
                        width: 12px;
                    }
                    .square {
                        width: 125px;
                        @media screen and (max-width: 550px) {
                            @include size(76.807px, 76.807px);
                        }
                    }
                }
            }
        }
        .info_button_wrapper {
            @include flexBetween();
            width: 100%;
            @media screen and (max-width: 550px) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 8px;
            }
            .title_subtitle {
                @include flColStart();
                gap: 10px;
            }

            .button_text {
                @include flexCenterBetween();
                padding-top: 148px;
                position: relative;
                @media screen and (max-width: 550px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 22px;
                    padding-top: 0;
                }
                @media screen and (max-width: 1200px) {
                    padding-right: 30px;
                }
                .slogan {
                    @include font(16px, "Geo-300", var(--m-text));
                    position: absolute;
                    right: 237px;
                    width: 240px;
                    @media screen and (max-width: 550px) {
                        position: initial;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@import "../../assets/mixins/mixins.scss";

.input_wrapper {
    @include font(12px, "Geo-300", var(--m-text));
    @include flColStart();
    width: 100%;
    gap: 4px;
    .label {
        text-transform: uppercase;
    }
    position: relative;
    .input {
        @include font(12px, "Geo-300", var(--m-text));
        @include flexStartCenter();
        width: 100%;
        padding: 13px 40px 13px 20px;
        border-radius: 12px;
        border: 1px solid #eee;
        caret-color: var(--m-color);
        transition: 0.3s;
        outline: none;
        &::placeholder {
            text-transform: none;
        }
        &:disabled {
            background: #f5f5f5;
            color: #c7c7c7;
        }
    }
    .input.error {
        border-color: #e00252;
    }
    .show_password {
        position: absolute;
        cursor: pointer;
        right: 20px;
        bottom: 10px;
    }
    .error_message {
        @include font(10px, "Geo-300", #e00252);
        position: absolute;
        bottom: -18px;
    }
}
